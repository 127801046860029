import { StudentLanguages } from "@cs124/person"
import { usePersonable } from "@cs124/personable"
import { Alert, Box, TextField } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Paper from "@mui/material/Paper"
import { useSession } from "next-auth/react"
import React, { ChangeEvent } from "react"
import { LoginButton } from "../login"
import { P } from "../material-ui"
import { StaffLanguage } from "./StaffLanguage"

export const ChooseLanguage: React.FC = () => {
  const { status } = useSession()
  const { course, updateLanguage } = usePersonable()

  if (status !== "authenticated") {
    return (
      <Alert severity="error">
        <P>Please log in to choose your language.</P>
        <LoginButton sx={{ marginTop: 1 }} />
      </Alert>
    )
  }

  if (!course?.role) {
    return (
      <Alert severity="error">
        <P>Only CS 124 students and staff can select a language.</P>
      </Alert>
    )
  }

  if (course?.isStaff) {
    return <StaffLanguage />
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateLanguage(StudentLanguages.check(event.target.value))
  }

  return (
    <Paper sx={{ paddingLeft: 4, paddingRight: 4, marginBottom: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <P sx={{ marginBottom: 0, fontSize: "1.4em" }}>My choice for my primary language in CS 124 is</P>
        </Box>
        <Box>
          <FormControl
            variant="filled"
            sx={{
              margin: 1,
              minWidth: 140,
            }}
          >
            <TextField size="small" select value={course?.language || ""} onChange={handleChange} label="Language">
              <MenuItem value={"kotlin"}>Kotlin</MenuItem>
              <MenuItem value={"java"}>Java</MenuItem>
            </TextField>
          </FormControl>
        </Box>
      </Box>
    </Paper>
  )
}
