import { usePersonable } from "@cs124/personable"
import {
  COMPLETED_THRESHOLD,
  Metadata,
  NewContent,
  SHAREABLE_INTERVAL,
  Shareable,
  ShareableProgress,
  VideoContent,
} from "@cs124/shareable"
import DeleteIcon from "@mui/icons-material/Delete"
import PublishIcon from "@mui/icons-material/Publish"
import {
  Alert,
  AlertTitle,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { green, yellow } from "@mui/material/colors"
import assert from "assert"
import getYouTubeID from "get-youtube-id"
import gravatar from "gravatar"
import moment from "moment"
import { useSession } from "next-auth/react"
import React, {
  CSSProperties,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useInView } from "react-intersection-observer"
import YoutubePlayer from "react-youtube"
import { Array } from "runtypes"
import { useSharing } from "."
import { DEVELOPMENT, GRAVATAR_OPTIONS, MAX_ASSISTANT_CONTRIBUTION_COUNT, SHAREABLE_SERVER } from "../../constants"
import { TOPBAR_OFFSET } from "../layout"
import { useLessons } from "../lessons"
import { LoginButton } from "../login"
import { A, P } from "../material-ui"
import makeLightDark from "../material-ui/makeLightDark"
import { DARK_DEFAULT_BACKGROUND_COLOR, LIGHT_DEFAULT_BACKGROUND_COLOR } from "../material-ui/theme"
import { ShareableRating } from "./ShareableRating"
import { ShareableSharing } from "./ShareableSharing"
import { INSTRUCTOR_WIDTH, NORMAL_WIDTH, PROGRESS_THICKNESS } from "./constants"

const YT_STATE_LOADED = 5

export const ShareableVideo: React.FC<
  PropsWithChildren<{
    metadata: string
    viewing?: string
    viewOnly?: boolean
    results?: string
    hardContributionLimit?: number
    noProgress?: boolean
  }>
> = ({ viewing, viewOnly, ...props }) => {
  const metadata = useMemo(() => Metadata.check(JSON.parse(props.metadata)), [props.metadata])
  const { path, language, semester } = metadata
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "400px 0px",
  })

  const { current: lesson, preview: lessonPreview } = useLessons()

  const { status } = useSession()
  const { course, headers } = usePersonable()
  const { updateSharing } = useSharing()

  const role = course?.role?.role

  let preview = false
  if (course?.isStaff) {
    preview = lesson ? lessonPreview : false
  }

  const [videos, setVideos] = useState<Shareable[] | undefined>(
    props.results && Array(Shareable).check(JSON.parse(props.results))
  )
  const [current, setCurrent] = useState<Shareable | undefined>()

  const [progressMap, setProgressMap] = useState<{ [key: string]: ShareableProgress }>({})

  const [valid, setValid] = useState<boolean | undefined>()
  const [duration, setDuration] = useState<number | undefined>()
  const [playbackRate, setPlaybackRate] = useState<number>()

  const [playing, setPlaying] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const autoplay = useRef<0 | 1>(0)
  const playerRef = useRef(undefined)
  const inputRef = useRef(null)

  const onChange = useCallback(
    event => {
      if (!course?.you || !course?.isStaff) {
        setValid(undefined)
        setDuration(undefined)
        return
      }

      if (event.target.value === "") {
        setValid(undefined)
        setDuration(undefined)
        return
      }

      const id = getYouTubeID(event.target.value, { fuzzy: false })
      setValid(id !== null)
      if (!id) {
        setDuration(undefined)
        return
      }
      const sharing = Shareable.check({
        type: "content",
        source: "cs124.org",
        path,
        language,
        id: "",
        duration: 0,
        content: {
          type: "video",
          kind: "youtube",
          videoID: id,
          private: false,
        },
        email: course.you.email,
        name: course.you.name.full,
        created: new Date(),
        instructor: course.staffRole === "instructor",
        disabled: false,
      })
      setCurrent(sharing)
      autoplay.current = 0
    },
    [course, path, language]
  )

  const [loaded, setLoaded] = useState(false)
  const firstRefresh = useRef(false)
  const refresh = useCallback(() => {
    if (!language) {
      return
    }
    let url = `${SHAREABLE_SERVER}/s/${path}?language=${language}`
    if (semester) {
      url += `&semester=${semester}`
    }
    fetch(url, {
      headers,
      credentials: "include",
    })
      .then(async response => {
        const { results: ws, progresses }: { results: Shareable[]; progresses?: ShareableProgress[] } =
          await response.json()
        if (progresses) {
          setProgressMap(progresses.reduce((r, x) => ({ ...r, [x["id"]]: x }), {}))
        }
        setVideos(ws)
        setCurrent(current => {
          let newCurrent = undefined
          if (!current && !firstRefresh.current) {
            newCurrent = ws[0]
          } else if (viewing) {
            newCurrent = ws.find(({ id }) => id === viewing)
          } else if (current && ws.find(({ id }) => current.id === id)) {
            newCurrent = current
          }
          firstRefresh.current = true
          return newCurrent
        })
      })
      .catch(() => {
        setCurrent(undefined)
        setVideos([])
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [language, path, headers, semester, viewing])

  useEffect(() => {
    inView && refresh()
  }, [inView, refresh])

  const shareVideo = useCallback(
    async (sharing: Shareable, duration: number) => {
      if (!sharing || status !== "authenticated" || duration === 0) {
        return
      }
      assert(sharing.content.type === "video")
      const response = await fetch(`${SHAREABLE_SERVER}/s/`, {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(
          NewContent.check({
            path: sharing.path,
            language: sharing.language,
            content: {
              type: "video",
              kind: "youtube",
              videoID: sharing.content.videoID,
              private: false,
              duration,
            },
          })
        ),
      })
      if (response.status !== 200) {
        console.error(response)
      }
      setCurrent(undefined)
      if (inputRef.current) {
        inputRef.current.value = ""
      }
      refresh()
      updateSharing()
    },
    [status, headers, refresh, updateSharing]
  )

  const deleteVideo = useCallback(
    (id: string) => {
      fetch(`${SHAREABLE_SERVER}/s/${id}`, {
        method: "DELETE",
        headers,
        credentials: "include",
      }).then(response => {
        response.status !== 200 && console.error(response)
        setCurrent(undefined)
        setPlaying(false)
        refresh()
      })
    },
    [headers, refresh]
  )

  const watchedTimes = useRef<{ [key: string]: { [key: number]: boolean } }>({})
  const [watched, setWatched] = useState<{ [key: string]: boolean }>({})
  const reported = useRef<{ [key: string]: boolean }>({})

  const videoID = current?.id
  const shouldReport = course?.you && videoID && playing

  useEffect(() => {
    if (!course?.you && playing) {
      playerRef.current?.pause()
    }
  }, [course?.you, playing])

  useEffect(() => {
    if (!shouldReport) {
      return
    }

    if (!(videoID in watchedTimes.current)) {
      watchedTimes.current[videoID] = {}
    }

    const reportInterval = (SHAREABLE_INTERVAL / 2 / playbackRate) * 1000
    const reportTimer = setInterval(() => {
      const currentTime = playerRef.current?.getCurrentTime()
      const duration = playerRef.current?.getDuration()

      const timeIndex = Math.floor(currentTime / SHAREABLE_INTERVAL)
      watchedTimes.current[videoID][timeIndex] = true

      const totalTimes = Math.ceil(duration / SHAREABLE_INTERVAL)
      const totalWatched = Object.keys(watchedTimes.current[videoID]).length

      if (totalWatched > totalTimes * COMPLETED_THRESHOLD) {
        setWatched(w => {
          w[videoID] = true
          return w
        })
        !reported.current[videoID] &&
          fetch(`${SHAREABLE_SERVER}/completed/${videoID}`, {
            method: "PUT",
            headers,
            credentials: "include",
          })
            .then(() => {
              reported.current[videoID] = true
            })
            .catch(err => {
              console.warn(`PUT /completed/${videoID} error: ${err}`)
            })
      }
      fetch(`${SHAREABLE_SERVER}/watched/`, {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ id: videoID, timestamp: currentTime, duration, playbackRate }),
      })
        .then(async r => {
          const { progress }: { progress?: ShareableProgress } = await r.json()
          if (progress) {
            setProgressMap(c => ({ ...c, [progress.id]: progress }))
          }
        })
        .catch(err => {
          console.warn(`POST /watched/ error: ${err}`)
        })
    }, reportInterval)
    return () => {
      clearInterval(reportTimer)
    }
  }, [shouldReport, videoID, headers, playbackRate])

  const seenStaff = useMemo(() => {
    if (!loaded || !videos || !progressMap) {
      return true
    }
    if (course?.isStaff && !DEVELOPMENT) {
      return true
    }
    let sawStaff = false
    let completedAny = false
    for (const video of videos) {
      if (!progressMap[video.id]) {
        continue
      }
      if (video.instructor) {
        sawStaff = true
      }
      if (video.instructor && progressMap[video.id].completed) {
        return true
      }
      if (progressMap[video.id].completed) {
        completedAny = true
      }
    }
    return sawStaff ? false : completedAny
  }, [loaded, videos, progressMap, course?.isStaff])

  const id = `sa_${path.replace("/", "_")}`
  const content = current && VideoContent.check(current.content)

  const canAdd =
    course?.isStaff &&
    role &&
    (role !== "assistant" || (videos && videos.length <= MAX_ASSISTANT_CONTRIBUTION_COUNT)) &&
    (props.hardContributionLimit === undefined || (videos && videos.length < props.hardContributionLimit)) &&
    !viewing &&
    !preview &&
    !viewOnly
  const canDelete =
    course &&
    current &&
    current.id !== "" &&
    (role === "instructor" || role === "head" || role === "headta" || current.email === course.you?.email) &&
    !preview
  const canRate = content && ((course?.isStaff && !preview) || watched[content.videoID])

  const canView = metadata.open || (course?.you && (course?.isStaff || moment(metadata.available).isBefore(moment())))
  let warning: ReactElement
  if (!canView) {
    warning = (
      <Box sx={restrictedSX}>
        <Alert severity="error">
          <AlertTitle>Content Restricted to Current CS 124 Students</AlertTitle>
          <P>
            A publicly-accessible version of this content is available at{" "}
            <A href="https://learncs.online">learncs.online</A>.
          </P>
          {status === "unauthenticated" && <LoginButton sx={{ marginTop: 1 }} />}
        </Alert>
      </Box>
    )
  }

  const isIntro = semester && path.endsWith(`/intro-${semester.toLowerCase()}`)
  const isOutro = semester && path.endsWith(`/outro-${semester.toLowerCase()}`)

  const noProgress = props.noProgress || isIntro || isOutro
  const missingBorder = loaded && !(isOutro || isIntro) && videos && videos.length === 0 && course?.isStaff
  const show = videos && (course?.isStaff || videos.length > 0)
  const canShowDescription = course?.isStaff && !preview && !viewing && !viewOnly

  return (
    <Box ref={ref} sx={{ position: "relative" }}>
      {show && (
        <>
          {warning && (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                opacity: 0.95,
                ...restrictedSX,
                zIndex: 100,
                ...makeLightDark(
                  {
                    backgroundColor: LIGHT_DEFAULT_BACKGROUND_COLOR,
                  },
                  {
                    backgroundColor: DARK_DEFAULT_BACKGROUND_COLOR,
                  }
                ),
              }}
            >
              {warning}
            </Box>
          )}
          <Box id={id} sx={{ position: "relative", top: -1 * TOPBAR_OFFSET }} />
          <Paper
            elevation={2}
            sx={{
              padding: 1,
              paddingBottom: 1,
              marginTop: 2,
              marginBottom: 2,
              ...makeLightDark(
                {
                  ...(missingBorder
                    ? { border: "4px solid LightSalmon" }
                    : !noProgress &&
                      !seenStaff && {
                        borderLeft: "4px solid LightSalmon",
                      }),
                },
                {
                  ...(missingBorder
                    ? { border: "4px solid IndianRed" }
                    : !noProgress &&
                      !seenStaff && {
                        borderLeft: "4px solid IndianRed",
                      }),
                }
              ),
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ flex: 1, maxWidth: 640 }}>
                {((videos && videos.length > 0) || current) && (
                  <YoutubePlayer
                    videoId={current ? content.videoID : ""}
                    className="yt-wrapper"
                    iframeClassName="yt-iframe"
                    onReady={event => {
                      playerRef.current = event.target
                      if (event.target.getPlayerState() === YT_STATE_LOADED) {
                        setDuration(event.target.getDuration())
                      }
                    }}
                    onStateChange={event => {
                      if (event.data === YT_STATE_LOADED) {
                        viewing && event.target.playVideo()
                        setDuration(event.target.getDuration())
                      }
                    }}
                    onPlaybackRateChange={() => {
                      setPlaybackRate(playerRef.current.getPlaybackRate())
                    }}
                    onPlay={() => {
                      setPlaying(true)
                      setPlaybackRate(playerRef.current.getPlaybackRate())
                    }}
                    onPause={() => {
                      setPlaying(false)
                    }}
                    onEnd={() => {
                      setPlaying(false)
                    }}
                    opts={{
                      host: "https://www.youtube-nocookie.com",
                      playerVars: { autoplay: autoplay.current, rel: 0, modestbranding: 1 },
                    }}
                  />
                )}
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 1,
                    }}
                  >
                    {current && (
                      <Typography variant="kbd">{`https://www.youtube.com/watch?v=${content.videoID}`}</Typography>
                    )}
                    {canDelete && (
                      <IconButton color="primary" size="small" onClick={() => deleteVideo(current.id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                  {canAdd && (
                    <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
                      <TextField
                        inputRef={inputRef}
                        margin="dense"
                        fullWidth
                        onChange={onChange}
                        error={valid === false}
                        label={valid === false && "Enter a valid YouTube link"}
                        placeholder="Add Your Explanation..."
                        variant="outlined"
                        sx={{ margin: 0 }}
                      />
                      {current && current.id === "" && duration && (
                        <Box sx={{ marginLeft: 1, marginRight: 1 }}>
                          <IconButton color="primary" size="small" onClick={() => shareVideo(current, duration)}>
                            <PublishIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  )}
                </>
              </Box>
            </Box>
            {!viewing && videos && videos.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Box>
                  {videos.map((video, i) => {
                    const { name, email, instructor } = video
                    const size = instructor ? INSTRUCTOR_WIDTH : NORMAL_WIDTH
                    const progress = progressMap[video.id]
                    let currentProgress = props.noProgress
                      ? 0
                      : progress
                        ? Math.ceil((progress.watched / progress.total) * 100)
                        : 0
                    if (progress && progress.completed && progress.watched + 1 === progress.total) {
                      currentProgress = 100
                    }
                    const avatar = (
                      <Box sx={{ position: "relative" }}>
                        <Avatar
                          sx={{ height: size, width: size, border: instructor ? `2px solid ${yellow[600]}` : "none" }}
                          src={gravatar.url(email, {
                            s: "48",
                            ...GRAVATAR_OPTIONS,
                          })}
                        />
                        <CircularProgress
                          size={size + PROGRESS_THICKNESS * 2}
                          thickness={PROGRESS_THICKNESS}
                          sx={{
                            position: "absolute",
                            left: -PROGRESS_THICKNESS,
                            top: -PROGRESS_THICKNESS,
                            color: `${green[400]}`,
                          }}
                          variant="determinate"
                          value={currentProgress}
                        />
                      </Box>
                    )

                    const visible = current && current.id === video.id
                    const tooltipTitle = !name
                      ? "Play Walkthrough"
                      : instructor
                        ? `Play Instructor ${name}'s Walkthrough`
                        : `Play ${name}'s Walkthrough`

                    return (
                      <Badge key={i} color="primary" variant="dot" overlap="circular" invisible={!visible}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            autoplay.current = 1
                            if (current && video.id === current.id) {
                              playerRef.current && playerRef.current.playVideo()
                            } else {
                              setCurrent(video)
                            }
                          }}
                        >
                          <Tooltip
                            placement="bottom"
                            sx={{
                              tooltip: {
                                maxWidth: "none",
                              },
                            }}
                            title={
                              <Box sx={{ textAlign: "center" }}>
                                {tooltipTitle}
                                {progress && !props.noProgress && (
                                  <>
                                    <Box component="br" />
                                    {`(${currentProgress}% Completed)`}
                                  </>
                                )}
                              </Box>
                            }
                          >
                            {avatar}
                          </Tooltip>
                        </IconButton>
                      </Badge>
                    )
                  })}
                </Box>
                {current && canRate && !viewOnly && <ShareableRating id={current.id} />}
                <Box
                  sx={{
                    visibility: canShowDescription && !showDescription ? "visible" : "hidden",
                  }}
                >
                  <Button onClick={() => setShowDescription(true)}>
                    <Typography variant="caption">Show Description</Typography>
                  </Button>
                </Box>
              </Box>
            )}
            {canShowDescription && showDescription && (
              <Box sx={{ marginTop: 2, marginBottom: -2 }} ref={descriptionRef}>
                {(props.children || isIntro || isOutro) && (
                  <>
                    <Typography paragraph variant="caption" sx={{ textAlign: "right" }}>
                      Video Instructions (Only Visible to Staff)
                    </Typography>
                    <hr />
                    {props.children ||
                      (isIntro && (
                        <P>Greet the students! Get them excited about the lesson. Be encouraging and supportive.</P>
                      )) ||
                      (isOutro && (
                        <P>Congratulate the students on completing yet another lesson. They&apos;re doing great!</P>
                      ))}
                  </>
                )}
              </Box>
            )}
            {course?.isStaff && <ShareableSharing noPaper />}
          </Paper>
        </>
      )}
    </Box>
  )
}
const restrictedSX: CSSProperties = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  paddingTop: 1,
}
